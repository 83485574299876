<template>
    <div class="content_box">
        <div class="contoiner_head">
            <img src="@/views/promotion/mealplan/img/plan_head1.jpg" alt="">
        </div>
        <div class="contoiner_body">
            <div class="contoiner_item">
                <div class="hotZoneBox">
                    <img class="hotZoneImg" src="@/views/promotion/mealplan/img/combo_box1.png" alt="">
                    <div class="hotZoneTitle">{{category_textArr[0]}}</div>
                    <div class="hotZoneGoods one">
                        <div class="hotZoneGoods-card" v-for="(item, index) in recommendGoodsList2.slice(0,4)" :key="index" @click="$router.pushToTab({ path: '/sku-' + item.sku_id + '?website_id='+filters.website_id+'&source_type=' + filters.website_type })">
                            <div class="goods-wrap">
                                <div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image)" @error="imageError(index)" /></div>
                                <h3>{{ item.goods_name }}</h3>
                                <p class="price">
                                    <span class="txt_label">套餐<br/>优惠价：</span>
                                    <span class="num">{{ item.price }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <p class="more">
                        <router-link class="list-item-lk" :to="{ path: '/list', query: { category_id: category_idArr[0], level: 2 } }" target="_blank">
                            查看套餐组合优惠 >>
                        </router-link>
                    </p>
                </div>
                <div class="hotZoneBox Box2" v-if="recommendGoodsList2.length>4">
                    <img class="hotZoneImg" src="@/views/promotion/mealplan/img/combo_box2.png" alt="">
                    <div class="hotZoneGoods second">
                        <div class="hotZoneGoods-card" v-for="(item, index) in recommendGoodsList2.slice(3,4)" :key="index" @click="$router.pushToTab({ path: '/sku-' + item.sku_id + '?website_id='+filters.website_id+'&source_type=' + filters.website_type })">
                            <div class="goods-wrap">
                                <div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image)" @error="imageError(index)" /></div>
                                <h3>{{ item.goods_name }}</h3>
                                <p class="price">
                                    <span class="txt_label">套餐<br/>优惠价：</span>
                                    <span class="num">{{ item.price }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <p class="more">
                        <router-link class="list-item-lk" :to="{ path: '/list', query: { category_id: category_idArr[0], level: 2 } }" target="_blank">
                            查看套餐组合优惠 >>
                        </router-link>
                    </p>
                </div>

                <div class="hotZoneBox">
                    <img class="hotZoneImg" src="@/views/promotion/mealplan/img/combo_box1.png" alt="">
                    <div class="hotZoneTitle">{{category_textArr[1]}}</div>
                    <div class="hotZoneGoods one">
                        <div class="hotZoneGoods-card" v-for="(item, index) in recommendGoodsList3.slice(0,4)" :key="index" @click="$router.pushToTab({ path: '/sku-' + item.sku_id + '?website_id='+filters.website_id+'&source_type=' + filters.website_type })">
                            <div class="goods-wrap">
                                <div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image)" @error="imageError(index)" /></div>
                                <h3>{{ item.goods_name }}</h3>
                                <p class="price">
                                    <span class="txt_label">套餐<br/>优惠价：</span>
                                    <span class="num">{{ item.price }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <p class="more">
                        <router-link class="list-item-lk" :to="{ path: '/list', query: { category_id: category_idArr[1], level: 2 } }" target="_blank">
                            查看套餐组合优惠 >>
                        </router-link>
                    </p>
                </div>
                <div class="hotZoneBox Box2" v-if="recommendGoodsList3.length>4">
                    <img class="hotZoneImg" src="@/views/promotion/mealplan/img/combo_box2.png" alt="">
                    <div class="hotZoneGoods second">
                        <div class="hotZoneGoods-card" v-for="(item, index) in recommendGoodsList3.slice(3,4)" :key="index" @click="$router.pushToTab({ path: '/sku-' + item.sku_id + '?website_id='+filters.website_id+'&source_type=' + filters.website_type })">
                            <div class="goods-wrap">
                                <div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image)" @error="imageError(index)" /></div>
                                <h3>{{ item.goods_name }}</h3>
                                <p class="price">
                                    <span class="txt_label">套餐<br/>优惠价：</span>
                                    <span class="num">{{ item.price }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <p class="more">
                        <router-link class="list-item-lk" :to="{ path: '/list', query: { category_id: category_idArr[1], level: 2 } }" target="_blank">
                            查看套餐组合优惠 >>
                        </router-link>
                    </p>
                </div>

                <div class="hotZoneBox">
                    <img class="hotZoneImg" src="@/views/promotion/mealplan/img/combo_box1.png" alt="">
                    <div class="hotZoneTitle">{{category_textArr[2]}}</div>
                    <div class="hotZoneGoods one">
                        <div class="hotZoneGoods-card" v-for="(item, index) in recommendGoodsList4.slice(0,4)" :key="index" @click="$router.pushToTab({ path: '/sku-' + item.sku_id + '?website_id='+filters.website_id+'&source_type=' + filters.website_type })">
                            <div class="goods-wrap">
                                <div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image)" @error="imageError(index)" /></div>
                                <h3>{{ item.goods_name }}</h3>
                                <p class="price">
                                    <span class="txt_label">套餐<br/>优惠价：</span>
                                    <span class="num">{{ item.price }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <p class="more">
                        <router-link class="list-item-lk" :to="{ path: '/list', query: { category_id: category_idArr[2], level: 2 } }" target="_blank">
                            查看套餐组合优惠 >>
                        </router-link>
                    </p>
                </div>
                <div class="hotZoneBox Box2" v-if="recommendGoodsList4.length>4">
                    <img class="hotZoneImg" src="@/views/promotion/mealplan/img/combo_box2.png" alt="">
                    <div class="hotZoneGoods second">
                        <div class="hotZoneGoods-card" v-for="(item, index) in recommendGoodsList4.slice(3,4)" :key="index" @click="$router.pushToTab({ path: '/sku-' + item.sku_id + '?website_id='+filters.website_id+'&source_type=' + filters.website_type })">
                            <div class="goods-wrap">
                                <div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image)" @error="imageError(index)" /></div>
                                <h3>{{ item.goods_name }}</h3>
                                <p class="price">
                                    <span class="txt_label">套餐<br/>优惠价：</span>
                                    <span class="num">{{ item.price }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <p class="more">
                        <router-link class="list-item-lk" :to="{ path: '/list', query: { category_id: category_idArr[2], level: 2 } }" target="_blank">
                            查看套餐组合优惠 >>
                        </router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { goodsSkuPage } from "@/api/goods/goods"
    import Config from '@/utils/config';
    import {getChildCategory2,getGoodsCategoryByToParentName} from "@/api/goods/goodscategory";

    export default {
        name: "list",
        components: {},
        data: () => {
            return {
                category_pid:Config.mealplanPid,
                recommendGoodsList2: [],
                recommendGoodsList3: [],
                recommendGoodsList4: [],
                category_idArr:[],
                category_textArr:[],
                loading: true,
                total: 0,
                filters:{
                    website_id:0,
                    website_type:0
                }
            }
        },
        mounted() {
            this.getCategoryIdByPid();
        },
        methods: {
            getCategoryIdByPid(){
                getGoodsCategoryByToParentName().then(res => {
                    if (res.code == 0 && res.data) {
                        if(res.data.length>0){
                            res.data.filter( (item,index) => {
                                this.category_idArr.push(item.category_id)
                                this.category_textArr.push(item.category_name)
                                this.getRecommendGoodsList(item.category_id, index)
                            })
                        }
                    }
                })
                    .catch(err => {
                        this.$message.error(err.message)
                    })
            },
            getRecommendGoodsList(c_id,type) {
                const params = {
                    page: 1,
                    page_size: 4,
                    category_id: c_id,
                    category_level:2,
                    recommend_way:3,
                }
                goodsSkuPage(params || {})
                    .then(res => {
                        const { count, page_count, list } = res.data
                        this.total = count
                        if(type == 0) {
                            this.recommendGoodsList2 = list
                        }else if(type == 1) {
                            this.recommendGoodsList3 = list
                        }else if(type == 2) {
                            this.recommendGoodsList4 = list
                        }
                        this.loading = false
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },
            imageError(index) {
                this.cargoList[index].sku_image = this.defaultGoodsImage
            },
        }
    }
</script>



<style lang="scss" scoped>
    .content_box{
        width: 100%;
        position: relative;
        min-height: 1200px;

        .contoiner_head{
            width: 1210px;
            margin: 0 auto;
            position: relative;
            text-align: center;
            img{
                width: 100%;
            }
        }
        .contoiner_body{
            position: relative;
            width: 1210px;
            margin: 0 auto;
            background-color: #be2017;
            padding:2px 0px 50px 0px;
            .contoiner_item{
                position: relative;
                width: 1063px;
                margin: 0 auto;
                min-height: 1000px;

                .more{
                    position: relative;
                    line-height: 32px;
                    height: 32px;
                    text-align: center;
                    margin-top: 5px;
                    a{
                        color: #B8B8B8;
                        font-size: 16px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                    }
                    a:hover{
                        color: $base-color;
                    }
                }
                .hotZoneBox{
                    position: relative;
                    width: 1063px;
                    height: 550px;
                    margin-top:75px;
                    .hotZoneImg{
                        width: 1063px;
                        height: 550px;
                        top: 0;
                        left: 0;
                        position: absolute;
                        z-index: 0;
                    }
                    .hotZoneTitle{
                        height:133px;
                        line-height: 110px;
                        font-size: 70px;
                        font-family: TsangerYuMo;
                        font-weight: normal;
                        color: #FFFFFF;
                        text-shadow: -2px 9px 7px #7c120d;
                        text-align: center;
                        position: relative;
                    }
                    .hotZoneGoods{
                        position: relative;
                        width: 900px;
                        height:295px;
                        margin: 0 auto;
                        /*background-color: #ff7674;*/
                        display: flex;
                        flex-wrap: wrap;

                        .goods-wrap {
                            width: 225px;
                            padding: 0px;
                            cursor: pointer;
                            .img-wrap {
                                width: 200px;
                                height: 200px;
                                margin: 0px auto;
                                text-align: center;
                                line-height: 200px;
                                box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
                                border-radius: 9px;
                                overflow: hidden;
                                position: relative;
                                img {
                                    border: none;
                                    position: absolute;
                                    margin-right: -75px;
                                    top: 50%;
                                    left: 50%;
                                    display: block;
                                    transform: translate(-50%, -50%);
                                }
                            }
                            h3 {
                                font-size: 14px;
                                line-height: 20px;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                word-break: break-all;
                                text-align: left;
                                overflow: hidden;
                                margin: 8px 18px 2px;
                                height: 42px;
                                color: #4C4C4C;
                            }
                            .price {
                                margin: 0 auto;
                                font-size: 13px;
                                text-align: center;
                                color: #fff;
                                width: 82%;
                                height: 36px;
                                background: #DD1402;
                                position: relative;
                                border-radius: 20px;
                                .txt_label{
                                    width: 55px;
                                    font-size: 12px;
                                    line-height: 14px;
                                    display: inline-block;
                                    position: absolute;
                                    top: 5px;
                                    left: 22px;
                                    text-align: left;
                                }
                                .num{
                                    margin-left: 50px;
                                    font-size: 26px;
                                    line-height: 36px;
                                    font-weight: 700;
                                    font-style:oblique
                                }
                            }
                        }
                    }
                    .hotZoneGoods.one{
                        margin-top: 50px;
                    }
                }
                .Box2{
                    height: 370px;
                    margin-top: 0;
                    padding-top: 85px;

                    .hotZoneImg{
                        width: 1063px;
                        height: 454px;
                        top: 0;
                        left: 0;
                        position: absolute;
                        z-index: 0;
                    }
                }
            }
        }
    }
</style>